



















import Component from "vue-class-component"
import OverviewStatus from "@/components/campaign/voiceAdminReview/OverviewStatus.vue"
import OverviewTime from "@/components/campaign/voiceAdminReview/OverviewTime.vue"
import OverviewAudio from "@/components/campaign/voiceAdminReview/OverviewAudio.vue"
import OverviewExternalCampaign from "@/components/campaign/voiceAdminReview/OverviewExternalCampaign.vue"
import OverviewChecklist from "@/components/campaign/voiceAdminReview/OverviewChecklist.vue"
import OverviewReject from "@/components/campaign/voiceAdminReview/OverviewReject.vue"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"

@Component({
  components: {
    OverviewReject,
    OverviewChecklist,
    OverviewExternalCampaign,
    OverviewAudio,
    OverviewTime,
    OverviewStatus
  }
})
export default class ReviewVoiceCampaign extends OverviewMixin {}
