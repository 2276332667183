
















































































































































































































































































































































































































import Component from "vue-class-component"
import { LocationService } from "@/services/locationService"
import { Watch } from "vue-property-decorator"
import { CampaignDirection } from "@/enums/campaignDirection"
import { CampaignType, Organization } from "@robocent/components"
import ScheduleMessageWrapper from "@/components/scheduler/elements/ScheduleMessageWrapper.vue"
import Multiselect from "vue-multiselect"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { VueComponent } from "@/utils/VueComponent"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"
import { Location, RawLocation } from "vue-router/types/router"
import BetaTag from "@/components/elements/BetaTag.vue"
import { links } from "@/data/links"
import router from "@/router"

const exceptRoutes = ["/voter-data", "/scheduler", "/"]

@Component({
  computed: {
    CampaignDirection() {
      return CampaignDirection
    }
  },
  components: { BetaTag, ScheduleMessageWrapper, Multiselect }
})
export default class NavSide extends VueComponent {
  types = CampaignType
  campaignDirection = CampaignDirection
  locationService = new LocationService()
  selectedOrganizations: Organization[] | null = null
  organizations: Organization[] = []
  isMultiselectOpen = false

  get redirectLink() {
    return `${process.env.VUE_APP_LOGOUT_URL}?orgId=${this.$store.getters.lastOrg?.uuid}`
  }

  get isPartner() {
    return this.$store.getters.user?.app_metadata?.partner
  }

  get isSalesManager() {
    return this.$store.getters.user?.app_metadata?.isSalesManager
  }

  get organizationExist() {
    return this.organization?._id
  }

  get organization() {
    return this.$store.getters.lastOrg
  }

  get organizationsPlaceholder() {
    return this.isMultiselectOpen
      ? "Type to find organization"
      : "Click to select organization"
  }

  async created() {
    if (this.organization) {
      this.selectDefaultOrg()
    }

    EventBus.getOrganizations$
      .pipe(whileComponentMounted(this))
      .subscribe(async (redirect) => {
        await this.updateOrganizationsList()

        if (redirect) {
          this.redirectToPage(redirect as Location)
        }
      })

    await this.getOrganizationsList()
  }

  async getOrganizationsList() {
    await this.request(async () => {
      const { data } = await OrganizationDao.getOrganizations({})
      this.organizations = data

      if (!this.selectedOrganizations?.length && data.length) {
        await this.updateSelectedOrganization(data[0])
      }
    })
  }

  async onSelectOrganization(selectedOrg: Organization) {
    this.$store.commit("setOrganization", selectedOrg)
    this.$store.commit("setLastVisitedOrganization", selectedOrg)

    if (!exceptRoutes.includes(this.$route.path)) {
      await this.$router.push({
        name: "Dashboard"
      })
    }
  }

  async updateOrganizationsList() {
    await this.getOrganizationsList()

    if (this.organizations.length) {
      await this.updateSelectedOrganization(this.organizations[0])
    } else {
      this.selectedOrganizations = []
    }
  }

  async updateSelectedOrganization(organization: Organization) {
    await this.onSelectOrganization(organization)
    this.selectedOrganizations = [organization]
  }

  selectDefaultOrg() {
    this.selectedOrganizations = [this.organization]
  }

  openCreateOrganizationModal() {
    this.$buefy.modal.open({
      width: 640,
      component: CreateNewOrganizationModal,
      parent: this
    })
  }

  navbarHandler(isActive: boolean) {
    this.$emit("is-active", isActive)
  }

  logout() {
    this.$store.commit("setAuth", false)
    this.$auth.logout({
      returnTo: process.env.VUE_APP_LOGOUT_URL
    })
  }

  redirectToPage(route: Location) {
    if (route?.params) {
      route.params.orgId = this.organization?._id
    }
    this.$router.push(route as RawLocation)
  }

  goToDocs() {
    window.open(links.main, "_blank")
  }

  goToNewApp() {
    window.open(process.env.VUE_APP_LOGOUT_URL, "_self")
  }

  goToVDR() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/voter-data/request-data`,
      "_self"
    )
  }

  goToProfile() {
    if (this.$store.getters.super) {
      return router.push("/profile")
    }
    window.open(`${process.env.VUE_APP_LOGOUT_URL}/profile`, "_self")
  }

  goToCarrierRegistration() {
    window.open(`${process.env.VUE_APP_LOGOUT_URL}/10dlc`, "_self")
  }

  goToBilling() {
    if (this.$store.getters.super) {
      return router.push({
        name: `Org Billing`,
        params: { orgId: this.organization._id }
      })
    }
    window.open(`${process.env.VUE_APP_LOGOUT_URL}/billing`, "_self")
  }

  goToCallbackNumbers() {
    window.open(`${process.env.VUE_APP_LOGOUT_URL}/callback-numbers`, "_self")
  }

  goToCIRL() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.organization?._id}/cirl`,
      "_self"
    )
  }

  goToScripts() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.organization?._id}/scripts`,
      "_self"
    )
  }

  goToFiles() {
    if (this.$store.getters.super) {
      return router.push({
        name: `Org Files`,
        params: { orgId: this.organization._id },
        query: { type: [] }
      })
    }
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.organization?._id}/uploads`,
      "_self"
    )
  }

  goToMessages() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.organization?._id}/messages`,
      "_self"
    )
  }

  goToSettings() {
    if (this.$store.getters.super) {
      return router.push({
        name: `Org Settings`,
        params: { orgId: this.organization._id }
      })
    }
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.organization?._id}/settings`,
      "_self"
    )
  }

  @Watch("$store.getters.super")
  updateSuperDirective() {
    this.$forceUpdate()
  }

  @Watch("organization")
  setChosenOrganizations(organization: Organization) {
    this.selectedOrganizations = [organization]
  }
}
