import moment from "moment"
import { ToastProgrammatic as Toast } from "buefy"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { FileUtils } from "@/utils/fileUtils"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import { Upload } from "@robocent/components"
import { BillingDao } from "@/services/dao/robocent/billingDao"

export class CampaignConfigureService {
  maxPorts = 150

  async createCampaign(
    voiceCampaign: VoiceCampaign,
    { maxPorts, active }: any
  ) {
    const campaign: any = await this.prepareCampaign(voiceCampaign)
    campaign.max_ports = maxPorts

    campaign.is_paused = !active
    return await CampaignConfigureDao.createCampaign(
      voiceCampaign._id!,
      campaign
    )
  }

  async prepareCampaign(campaign: VoiceCampaign) {
    if (campaign.type === "robocall") {
      return await this.prepareRobocallCampaign(campaign)
    }

    if (campaign.type === "transfer") {
      return await this.prepareTransferCampaign(campaign)
    }
  }

  async prepareBaseCampaign(campaign: VoiceCampaign) {
    const baseValues: any = {}
    const callerId = await this.getCallerId(campaign._id!)

    const name = FileUtils.getNormalizedName(campaign.organization.name)
    baseValues.name = `id:${campaign.humanId} | ${name}`
    baseValues.start_date = this.formatTime(campaign)
    baseValues.end_date = this.formatTime(campaign)
    baseValues.max_ports = this.maxPorts
    baseValues.callerids = [callerId]
    baseValues.lists = [
      Number(campaign.meta.stratics && campaign.meta.stratics.phoneListId)
    ]

    return baseValues
  }

  formatTime(campaign: VoiceCampaign) {
    return moment.utc(campaign.time).tz(campaign.timezone).format("YYYY-MM-DD")
  }

  isCampaignActive(campaign: VoiceCampaign) {
    return (
      (campaign.organization.meta as any).postPayEnabled &&
      campaign.organization.meta.autoPayEnabled
    )
  }

  async getCallerId(campaignId: string) {
    try {
      const callerId = (await CampaignConfigureDao.getCallerId(campaignId)).data
      return callerId && callerId.callerid_id
    } catch (e) {
      Toast.open({
        type: "is-danger",
        message: "Error during load caller ID from dialer"
      })
      return null
    }
  }

  async prepareRobocallCampaign(voiceCampaign: VoiceCampaign) {
    const campaign: any = await this.prepareBaseCampaign(voiceCampaign)
    campaign.schedule_id =
      voiceCampaign.meta.stratics && voiceCampaign.meta.stratics.scheduleId
    campaign.is_paused = !this.isCampaignActive(voiceCampaign)
    campaign.ring_duration = 45
    campaign.port_algorithm = this.getPortAlgorithm(voiceCampaign.type)
    campaign.use_amd = Boolean(voiceCampaign.uploads.vmRecording)
    campaign.allow_nat_dnc = false

    campaign.answer_msg = FileUtils.normalizeAudioFileName(
      voiceCampaign.uploads.liveRecording as any,
      voiceCampaign.organization.name
    )

    if (voiceCampaign.uploads.vmRecording) {
      campaign.machine_msg = FileUtils.normalizeAudioFileName(
        voiceCampaign.uploads.vmRecording as any,
        voiceCampaign.organization.name
      )
    }

    return campaign
  }

  async prepareTransferCampaign(voiceCampaign: VoiceCampaign) {
    const robocallCampaign = await this.prepareRobocallCampaign(voiceCampaign)
    delete robocallCampaign.answer_msg
    const campaign = robocallCampaign

    campaign.initial_ivr =
      voiceCampaign.meta.stratics && voiceCampaign.meta.stratics.ivrId
    campaign.outbound_type = "PRESS1"

    return campaign
  }

  async prepareRvmCampaign(voiceCampaign: VoiceCampaign) {
    const campaign = await this.prepareBaseCampaign(voiceCampaign)

    campaign.schedule =
      voiceCampaign.meta.stratics && voiceCampaign.meta.stratics.scheduleId
    campaign.audio = FileUtils.straticsAudioPath(
      voiceCampaign.uploads.vmRecording as any,
      voiceCampaign
    )
    campaign.active = Number(this.isCampaignActive(voiceCampaign))

    return campaign
  }

  getPortAlgorithm(type: VoiceCampaignType) {
    if (type === "robocall") {
      return "SIMPLE"
    }
    if (type === "transfer") {
      return "PER-AGENT"
    }

    return null
  }

  prepareAgentPool(campaign: VoiceCampaign) {
    return {
      name: campaign.humanId,
      xfer_address: campaign.transferNumber,
      max_xfers: this.getTransferMaxPorts(campaign),
      record_xfer: 0
    }
  }

  async createAgentPool(campaign: VoiceCampaign, maxPorts: number) {
    const agentPool = this.prepareAgentPool(campaign)
    agentPool.max_xfers = maxPorts
    return await CampaignConfigureDao.createAgentPool(campaign._id!, agentPool)
  }

  async createIvr(campaign: VoiceCampaign) {
    const ivr = this.prepareIvr(campaign)
    return await CampaignConfigureDao.createIvr(campaign._id!, ivr)
  }

  prepareIvr(campaign: VoiceCampaign) {
    return {
      name: campaign.humanId,
      item_timeout_length: 5,
      question_repeat_count: 1,
      results_email_on: "NEVER",
      items: this.transferItems(campaign)
    }
  }

  getTransferMaxPorts(campaign: VoiceCampaign) {
    return campaign.meta.transferMaxPorts || this.maxPorts
  }

  transferItems(campaign: VoiceCampaign) {
    return [
      {
        name: "Press 1",
        report_header: "press1",
        item_type: "QUESTION",
        item_order: "0",
        audio_msg: FileUtils.straticsAudioPath(
          campaign.uploads.liveRecording as unknown as Upload,
          campaign
        ),
        options: [
          {
            name: "Transfer",
            dtmf: "1",
            action: "TRANSFER",
            agent_pool: campaign.meta.stratics.agentPoolId
          },
          {
            name: "Do-Not-Call",
            dtmf: "9",
            action: "DNC",
            add_to_dnc: true
          }
        ]
      }
    ]
  }

  async approveCall(data: any, campaign: VoiceCampaign) {
    return await BillingDao.approveCall(
      Object.assign({ callId: campaign._id }, data, {
        straticsId: campaign.meta.straticsId || data.straticsId
      })
    )
  }
}
