







































import Component from "vue-class-component"
import { get } from "lodash"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import { AudioService } from "@/services/voice/audioService"
import { CampaignConfigureService } from "@/services/voice/campaignConfigureService"
import { VoicePhoneListService } from "@/services/voice/voicePhoneListService"
import { ScheduleService } from "@/services/voice/scheduleService"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import ConfirmAndApproveModal from "@/components/campaign/voiceAdminReview/modals/ConfirmAndApproveModal.vue"
import RejectCallModal from "@/components/campaign/voiceAdminReview/modals/RejectCallModal.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { ApproveVoiceCampaign } from "@/interfaces/voiceCampaign/approveVoiceCampaign"

@Component
export default class VoiceCampaignReviewHeader extends OverviewMixin {
  campaignConfigureService = new CampaignConfigureService()
  audioService = new AudioService()
  scheduleService = new ScheduleService()
  phoneListService = new VoicePhoneListService()

  get isAcceptFlowUnAvailable() {
    return (this.status && this.status?.isCallApproved) || this.isDeleted
  }

  created() {
    if (this.isSurvey) {
      EventBus.approveCampaign$
        .pipe(whileComponentMounted(this))
        .subscribe((data) =>
          this.doPostConfirmSurveySteps(data as ApproveVoiceCampaign)
        )
    } else {
      EventBus.approveCampaign$
        .pipe(whileComponentMounted(this))
        .subscribe((data) =>
          this.doPostConfirmSteps(data as ApproveVoiceCampaign)
        )
    }
  }

  async doPreConfirmSteps() {
    await this.request(async () => {
      if (!this.status.isAudioReady) {
        await this.audioService.uploadAudioFiles(this.campaign)
        this.status.isAudioReady = true
      }

      if (!this.status.isCallerIdReady) {
        await CampaignConfigureDao.createCallerId(this.campaign!._id!)
        this.status.isCallerIdReady = true
      }

      if (!this.status.isPhoneListReady) {
        await this.phoneListService.processPhoneListUploading(this.campaign)
        this.status.isPhoneListReady = true
      }

      if (!this.status.isScheduleReady) {
        await this.scheduleService.createSchedule(this.campaign)
        this.status.isScheduleReady = true
      }

      if (!this.status.isCampaignReady) {
        await this.updateCampaign()
      }

      this.openConfirmAndApproveModal()
    })
  }

  async doPostConfirmSteps(data: ApproveVoiceCampaign) {
    this.loading = true
    try {
      if (this.isTransfer) {
        await this.transferSteps(data.transferMaxPorts!)
        await this.updateCampaign()
      }

      if (!this.status.isCampaignReady) {
        await this.campaignConfigureService.createCampaign(this.campaign, data)
        await this.updateCampaign()
      }

      if (!this.status.isCallApproved) {
        await this.campaignConfigureService.approveCall(data, this.campaign)
      }

      await this.updateCampaign()
      this.setCampaignApproved()
    } catch (e) {
      console.error(e)
      this.$buefy.toast.open({
        message:
          get(e, "response.data.message") || "Error create campaign in dialer",
        type: "is-danger"
      })
    } finally {
      this.loading = false
    }
  }

  async transferSteps(maxPorts: number) {
    if (!this.status.isAgentPoolReady) {
      await this.campaignConfigureService.createAgentPool(
        this.campaign,
        maxPorts
      )
      this.status.isAgentPoolReady = true
    }

    await this.updateCampaign()

    if (!this.status.isIvrReady) {
      await this.campaignConfigureService.createIvr(this.campaign)
      this.status.isIvrReady = true
    }
  }

  async doPreSurveyConfirmSteps() {
    await this.request(async () => {
      if (this.status.isPhoneListReady) {
        await this.confirmSurvey()
      } else {
        await this.phoneListService.processPhoneListUploading(this.campaign)
        await this.updateCampaign()
        await this.confirmSurvey()
      }
    })
  }

  async confirmSurvey() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmAndApproveModal,
      width: 600
    })
  }

  async doPostConfirmSurveySteps(data: ApproveVoiceCampaign) {
    await this.request(async () => {
      await this.campaignConfigureService.approveCall(data, this.campaign)
      await this.updateCampaign()
    })
    this.setCampaignApproved()
  }

  setCampaignApproved() {
    this.status.isCallApproved = true
  }

  async openConfirmAndApproveModal() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmAndApproveModal,
      width: 600
    })
  }

  confirmReject() {
    this.$buefy.modal.open({
      parent: this,
      component: RejectCallModal,
      width: 600,
      events: {
        confirm: () => this.updateCampaign()
      }
    })
  }
}
